
.box-score-container {
    padding: 20px;
  }
  
  .team-box-score {
    margin-bottom: 30px;
  }
  .team-box-score td {
    align-items: center !important;
  }
  .team-box-score h3 {
    margin-bottom: 10px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  /* th {
    background-color: #f4f4f4;
  } */
  
  tfoot tr {
    background-color: #f4f4f4;
  }
  
  .bold {
    font-weight: bold;
  }
  
  .box-score-container h2{
    align-items: center;
    justify-content: center;
  }