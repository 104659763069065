html, body{
  height: 100%;
  margin: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.page-header {
  padding: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.page-header p {
  margin: 0;
  font-size: 2.5rem;
  font-weight: bold;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.site-title {
  margin: 0;
  font-size: 2rem;
}

.navbar {
  background-color: black;
  color: gold;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0 1rem;
}

.navbar a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 3rem;
  padding: .25rem;
}

.navbar ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.navbar li.active {
  background-color: #555;
}

.navbar li:hover {
  background-color: #777;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    gap: 1rem;
  }

  .navbar a {
    gap: 1rem;
  }
  
}
