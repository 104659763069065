   .list-item {
    padding: 10px;
  }
  
  .avatar {
    width: 100px !important;
    height: 80px !important;
    background-color: white !important;
    border-radius:0% !important;
    margin-right: 10px;
  }
  
  .avatar img {
    width: 100% !important;
    height: 75% !important;
    object-fit: fill !important;
    border-radius: 0% !important;
  }
  
  .team-name {
    font-size: 1.5rem !important;
    margin-left: 5px;
  }
  
  .team-medals {
    font-size: 1.2rem;
    margin-left: 5px;
  }

 