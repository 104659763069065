.hero-container {
    background: url('../assets/images/LePhoto.png') 
        center center/cover no-repeat;
    height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:flex-start;

    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    color: white;
    font-size: 1.8rem;

}

.title {
    font-weight: bold;
    font-size: 4rem;
    text-align: left;
    margin-top: 8rem;
    width: 45%;
    margin-left: 2.5rem;
}

.year-text {
    margin-top: 0rem;
    margin-left: 2.5rem;
    font-size: 3rem;
    font-weight: bold;
    color: gold;
    text-align:left;
}

@media (max-width: 768px) {
    .title {
        font-size: 2rem;
        width: 100%;
        margin-left: 0;
    }
    .year-text {
        font-size: 1.5rem;
        margin-left: 0;
    }
}


